import { goto } from '$app/navigation'

export type ErrorSetter = Dispatch<string>

export const clearInsertTripOnLoadParams = (): Promise<unknown> => {
	const url = new URL(window.location.href)
	const query = url.searchParams

	if (query.has('insertTripOnLoad')) {
		query.delete('insertTripOnLoad')
		url.search = query.toString()
		return goto(url.toString())
	}
}

export const loginAndInsertTrip = (
	signInAsync: () => Promise<void>,
	insertOperation: () => void,
	setErrorString?: ErrorSetter,
) => {
	setInsertTripCallbackState()
	return signInAsync()
		.then(insertOperation)
		.catch(() => {
			if (setErrorString) {
				setErrorString('Error logging in. Please try again.')
			}
		})
}

export function setInsertTripCallbackState() {
	const url = new URL(window.location.href)
	const urlParams = url.searchParams

	urlParams.set('insertTripOnLoad', 'true')

	url.search = urlParams.toString()
	return goto(url.toString())
}
